.Container{

  width: 100%;
  max-width: 900px;
  padding: 0 40px;
  margin: 0 auto;

  @media(min-width: 1200px) and (max-width: 1400px){

    max-width: 1000px;

  }
  @media(min-width: 1400px) and (max-width: 1800px){

    max-width: 1200px;

  }
  @media(min-width: 1800px){

    max-width: 1600px;

  }

}

.Row{

  display: flex;
  margin: 2.5em -40px;
  flex-wrap: wrap;

  @include mq(s-tablet){

    margin: 5em -40px;


  }

  &__Col{

    width: 100%;
    padding: 0 40px;
    @include mq(s-tablet){
      width: 50%;
    }

    &Large{

      width: 100%;
      padding: 0 40px;

      @include mq(desktop){
        width: 50%;
      }

    }

  }

  &__OneCol{

    padding: 0 40px;
    width: 100%;

  }


  &--valign{

    align-items: center;

  }

  &--inverse{

    @include mq(s-tablet){
      flex-direction: row-reverse;
    }

  }

  &--news{

    > div:first-child{

      width: 100%;

      @include mq(s-tablet) {
        width: 40%;
      }

      h2{
        padding-right: 0;
        padding-left: 0;
        z-index: 1;

        @include mq(s-tablet) {
          left: 20%;
        }
        position: relative;

      }

    }

    > div:last-child{

      width: 100%;

      @include mq(s-tablet) {
        width: 60%;
      }

      img{

        margin-top: 30px;
        @include mq(s-tablet) {
          margin-top: 0;
          float: right;
          width: 120%;
        }

      }

    }

    &.Row--inverse{

      > div:first-child{

        text-align: right;
        h2{
          text-align: left;
          @include mq(s-tablet) {
            left: -10%;
          }

        }

      }

      > div:last-child{

        img{

          float: left;

        }

      }



    }

  }

}

.TextIndent{

  overflow: hidden;

  @include mq(l-tablet){
    padding-left: 2.5em;
  }

  &--large{

    @include mq(s-tablet) {
      padding-left: 30%;
      padding-right: 10%;
    }

  }

}

#main{

  overflow: hidden;
  position: relative;

  //&:before{
  //
  //  width: 2px;
  //  height: 50px;
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: calc(70% - 20px);
  //  background: #000;
  //
  //  @include mq(s-tablet){
  //
  //    right: calc(20% + 18px);
  //    left: auto;
  //    margin-left: 0;
  //
  //  }
  //
  //}

}

.willtransform{

  will-change: transform;

}