.ImagesSlider{

  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 70px;

  &__Wrapper{

    position: relative;

  }

  &__Paging{

    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 20px;

    button{

      width: 26px;
      height: 26px;
      display: inline-block;
      border: none;
      background: none;
      position: relative;
      opacity: 0.5;
      transition: opacity 0.3s;
      cursor: pointer;

      &:before{

        width: 10px;
        height: 10px;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        background: #000;
        border-radius: 50%;

      }

      .no-touchevents &:hover,
      &.__active{

        opacity: 1;

      }


    }

  }

  &__Slide{

    .__navigation-fade &{

      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      width: 100%;
      height: auto;

      &.__active{

        opacity: 1;
        z-index: 5;
        position: relative;

      }

    }

  }

}