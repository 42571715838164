.Privacy{

  position: absolute;
  top: 0;
  left: 0;
  display: none;
  color: #fff;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  background: #000;
  z-index: 200;
  overflow: hidden;

  .Title2{
    padding-left: 0;
  }

  &__Inner{

    width: 94vw;
    left: 3vw;
    top: 100px;
    height: calc(100vh - 150px);
    overflow: hidden;
    position: absolute;
    overflow: scroll;

  }

}

