.cursor{

  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 3px solid #000;
  border-radius: 50%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  .touchevents &{

    display: none!important;

  }

  .show-cursor & {
    opacity: 1;
    visibility: visible;
  }

    &:before,
  &:after{

    width: 13px;
    height: 2px;
    border-radius: 1px;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #000;
    transform: translate(-50%,-50%);

  }

  &:after{

    transform: translate(-50%,-50%) rotate(-90deg);

  }

  &--left{

    &:before{

      transform: translate(-50%,-4px) rotate(-45deg);

    }

    &:after{

      transform: translate(-50%,4px) rotate(45deg);

    }

  }

  &--right{

    &:before{

      transform: translate(-50%,-4px) rotate(45deg);

    }

    &:after{

      transform: translate(-50%,4px) rotate(-45deg);

    }

  }

}

