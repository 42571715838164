.Loader{

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #000;

  &__Logo{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    fill: #fff;
    width: 120px;

  }

  &__Bar{

    width: 50px;
    height: 0%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;

  }

}