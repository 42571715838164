.LoadBar{

  width: 80px;
  height: 0;
  background: #000;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: width 0.5s $ease1;
  z-index: 1;

  @include mq(false, l-tablet){
    display: none;
  }

  .touchevents &{

    display: none;

  }


  img{
    width: 6px;
    height: 11px;
    position: absolute;
    top: 5vh;
    left: 40px;
    margin-left: -3px;
    margin-top: -5px;

  }

  .on-page &{

    height: 100%;

  }

  .no-touchevents &:hover{

    width: 100px;

  }

}